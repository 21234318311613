.page {
    max-width: 1440px;
    width: 98%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    font-family: Roboto;
}

@media only screen and (max-width: 600px) {
    .page  {
    padding: 40px 20px 75px 20px;
    width: 100%;
    }
  }

.fullpage-modal  {
    max-width: 1250px;
    width: 98%;
    height: auto;
    margin: 0 auto;
    margin-top: 40px;
    box-shadow: 5px 10px 18px #7a7979;
    border-radius: 5px;
}
.pageContent {
    margin-top: 20px;
    padding-bottom: 20px;
}
.pageHeader {
    margin-top: 0;
}

.form-field {
    margin-bottom: 20px;
}
.form-field label {
    font-family: Roboto,sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}
.form-field input[type="text"] {
    display: block;
    width: 100%;
    height: 44px;
    margin-top: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 0 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
}
.form-field textarea {
    display: block;
    width: 100%;
    /* height: 120px; */
    background: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
}
.form-field select {
    display: block;
    width: 100%;
    height: 50px;
    padding: 5px;
    background: none;
    border: 1px solid #ddd;
    font-family: Roboto,sans-serif;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
}
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .form-field select, .form-field textarea, .form-field input[type="text"] {
        font-size: 16px;
    }
}
.form-submit {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    width: 100%;
}
.form-submit:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.auth-form-submit:hover {
    opacity: 0.8;
}
.form-cancel {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    font-size: 14px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #a6a6a6;
    color: #fff;
    width: 100%;
    margin-top: 25px;
}

.table, .table th, .table td {
    border-bottom: 1px solid #cfcdcd;
    border-collapse: collapse;
}
.table {
    width: 100%;
}
.none-screen {
    text-align: center;
}
.info-modal, .confirm-modal {
    position: absolute;
    background: white;
    border: 1px solid #333333;
    outline: none;
}
.info-modal {
    top: calc(50% - 60px);
    left: calc(50% - 200px);
    right: calc(50% - 200px);
    bottom: calc(50% - 60px);
    padding: 20px 30px 20px 50px;
}
.confirm-modal {
    top: calc(50% - 70px);
    left: calc(50% - 190px);
    right: calc(50% - 190px);
    bottom: calc(50% - 70px);
    text-align: center;
    padding: 20px 0;
}
.confirm-modal-big {
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.confirm-modal-history{
    top: calc(50% - 120px);
    left: calc(50% - 220px);
    right: calc(50% - 220px);
    bottom: calc(50% - 120px);
    padding: 20px;
}
.info-modal-close, .confirm-modal-close {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    outline: none;
}

.k-confirm-modal-close {
    background: transparent;
    border: none;
    padding: 0 !important;
    margin:0 !important;
    cursor: pointer;
    font-size: 18px;
    outline: none;
}
.info-main-text {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1em;
}
.info-sub-text {
    font-size: 0.9em;
}
.confirm-main-text {
    margin-top: 0.2em;
    font-size: 1em;
    margin-bottom: 0.4em;
}
.confirm-sub-text {
    font-size: 0.9em;
}

.button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    line-height: 1.5;
    border: none;
    padding: 9px 28px;
    border-radius: 40px;
    letter-spacing: 0;
    outline: none;
    text-decoration: none;
    background: #ef3354;
    color: #fff;
    font-size: 11px;
}

.approveDeclineSet {
    margin-top: 20px;
}
.approveDeclineSet button {
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 1px 0 rgba(0,0,0,0.3);
    font-family: inherit;
    border-radius: 20px;
    outline: none;
}
.approveDeclineSet button:hover {
    opacity: 0.7;
}
.approve {
    background: #00b200;
}
.decline {
    background: #f64e4c;
}
button.neutral {
    background: #eeeeee;
    color: black;

}

.input-modal {
    position: absolute;
    top: 10%;
    left: calc(50% - 200px);
    width: auto;
    min-width: 30%;


    background-color: white;
    border: 1px solid lightgrey;
    /* padding: 20px; */
    box-shadow: 5px 10px 18px #7a7979;
    border-radius: 5px;
}
.required span:before {
    content: "*";
    color: red;
  }


  .k-btn {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 44px;

  }
  @media only screen and (max-width: 600px) {
    .k-btn-responsive  {
       margin-top: 10px;
      width: 100%;
    }
  }

  .k-btn-primary {
    color: #ffffff;
    background-color: #23315F;
    border-color: #23315F;
  }
  .k-btn-outline-primary {
    /* background-color: #ffffff; */
    opacity: 1;
    color: #23315F;
    border-color: #23315F;
  }

.k-btn-primary:focus,
.k-btn-primary.focus {
  color: #212020;
  background-color: white;
  border-color: #122b40;
}
.k-btn-outline-primary:focus,
.k-btn-outline-primary.focus {
  color: white;
  background-color: #23315F;
  border-color: #122b40;
}
.k-btn-primary:hover {
    color: #413e3e;
    background-color: white;
    border-color: #204d74;
  }
  .k-btn-outline-primary:hover {
    color: white;
    background-color: #23315F;
    border-color: #122b40;
  }

  .k-btn-primary-full {
    color: #ffffff;
    background-color: #23315F;
    border-color: #23315F;
    width: 100%;
  }
  .k-btn-secondary {
    color: #23315F;
    border-color: #23315F;
  }
  .k-btn-secondary-full {
    color: #23315F;
    border-color: #23315F;
    width: 100%;
    margin-top: 10px;
  }

  .k-status {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
  }
  
  .k-status-ok {
    background-color: #c2efe0;
    color: #31C48D4D;


  }
  .k-status-pending {
    color: #7939004d;
    background-color: #fbc392;

  }
  
 input[type="file"] {
    display: none;
 }
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    margin-top: 10px;
    background-color: #4B5563;
    color: white;
    padding: 10px 10px;
    border-radius: 8px;
    font-size: 14px;
    
}

.form-input {
    margin-top: 20px;
}

.no-margin {
    margin-top: 0 !important ;
    margin-left: 0 !important;
    margin-right: 0 !important;
    
}

td,
th {
  padding: 0;
  vertical-align: middle
}
